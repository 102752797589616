<template>
    <div class="logEntry">
        <LogHeading :action_flag="1" transport :heading="data.transport.destination" :informationString="[
            data.transport.username,
            data.transport.date,
            data.transport.time,
        ]" :showAtts="showAtts" @click="toggleAtts" />
        <ul v-if="showAtts" class="atts">
            <li v-for="log in data.logs" :key="log.id">
                {{ log.heading_name }}
            </li>
        </ul>
    </div>
</template>

<script>
import LogHeading from "./entry/LogHeading.vue";

export default {
    name: "TransportLogEntry",
    components: { LogHeading },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showAtts: false,
        };
    },
    methods: {
        toggleAtts() {
            this.showAtts = !this.showAtts;
        },
    },
};
</script>

<style scoped lang="sass">
.atts
    margin: 0
    border: 2px solid $color_primary
    border-radius: 0 0 10px 10px
    border-top: none
    padding: 5px
    padding-left: 2rem
    padding-bottom: 15px

    font-size: 1.1rem
</style>