<template>
    <div class="logHeader" :class="{ roundBottomRadius: !showAtts }">
        <div class="actionIcon">
            <slot name="actionIcon">
                <span
                    class="my-material-icons"
                    v-if="action_flag == 0"
                    title="Hinzugefügt"
                    >add</span
                >
                <span
                    class="my-material-icons"
                    v-else-if="action_flag == 1 && transport"
                    title="Bearbeitet"
                    >local_shipping</span
                >
                <span
                    class="my-material-icons"
                    v-else-if="action_flag == 1"
                    title="Bearbeitet"
                    >edit</span
                >
                <span
                    class="my-material-icons"
                    v-else-if="action_flag == 2"
                    title="Entfernt"
                    >delete</span
                >
            </slot>
        </div>
        <p class="heading">
            <slot name="heading">
                {{ heading }}
            </slot>
        </p>
        <p class="date">
            <span v-if="deleted" class="my-material-icons" title="Gelöscht"
                >delete</span
            >
            {{ finalInformationString }}
        </p>
        <div class="add_icons">
            <slot name="icons"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LogHeading',
    props: {
        action_flag: {
            type: Number,
            required: true,
        },
        heading: {
            type: String,
            required: true,
        },
        transport: {
            type: Boolean,
            default: false,
        },
        showAtts: {
            type: Boolean,
            default: false,
        },
        informationString: {
            type: Array,
            default: [],
        },
        deleted: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        finalInformationString() {
            let string = '';
            for (let item of this.informationString) {
                if (item) string += item + ' - ';
            }
            if (string.length > 3) string = string.slice(0, string.length - 3);
            return string;
        },
    },
};
</script>

<style scoped lang="sass">
.logHeader
    display: grid
    grid-template-areas: "actionIcon username icons" "actionIcon date icons"
    grid-template-columns: min-content 1fr min-content
    gap: 0 10px
    padding: 10px
    background-color: $color_primary
    color: #FFF
    margin-top: 10px
    border-radius: 10px 10px 0 0

.roundBottomRadius
    border-radius: 10px

.add_icons, .actionIcon
    font-size: 1.5rem
    align-self: center
    justify-self: center
    width: fit-content

.add_icons
    grid-area: icons
.actionIcon
    grid-area: actionIcon

p
    margin: 0


.heading
    font-size: 1.2rem
    font-weight: bold
    grid-area: username
    user-select: none

.date
    display: flex
    grid-area: date
    font-size: .8rem
    opacity: .6
    user-select: none
    align-items: center
    gap: .3em
</style>
