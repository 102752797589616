<template>
    <div class="lds-ellipsis" :class="{ primaryColor }" :style="cssVars">
        <div :style="style"></div>
        <div :style="style"></div>
        <div :style="style"></div>
        <div :style="style"></div>
    </div>
</template>

<script>
export default {
    name: "MovingPoints",
    props: {
        primaryColor: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
        },
        box: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: "1rem"
        },
    },
    computed: {
        cssVars() {
            return {
                '--ballSize': this.size,
                '--height': this.box ? 'calc(var(--ballSize) * 5)' : this.size,
                '--top': this.box ? 'calc(var(--ballSize) * 2)' : 0
            }
        },
        style() {
            if (!this.primaryColor && this.color)
                return { backgroundColor: this.color };
        },
    },
};
</script>

<style lang="sass" scoped>
.lds-ellipsis.primaryColor
    div
        background: $color_primary

.lds-ellipsis
    --size: calc(var(--ballSize) * 5)

    display: inline-block
    position: relative
    width: var(--size)
    height: var(--height)

    div
        position: absolute
        // top: calc(0.4125 * var(--height)) // 33px
        top: var(--top) // 33px
        width: calc(0.20 * var(--size)) //13px
        height: calc(0.20 * var(--size))
        border-radius: 50%
        background: #FFF
        animation-timing-function: cubic-bezier(0, 1, 1, 0)

    div:nth-child(1)
        left: calc(0.1 * var(--size)) //8px
        animation: lds-ellipsis1 0.6s infinite

    div:nth-child(2)
        left: calc(0.1 * var(--size)) //8px
        animation: lds-ellipsis2 0.6s infinite

    div:nth-child(3)
        left: calc(0.4 * var(--size))//32px
        animation: lds-ellipsis2 0.6s infinite

    div:nth-child(4)
        left: calc(0.7 * var(--size)) //56px
        animation: lds-ellipsis3 0.6s infinite

@keyframes lds-ellipsis1
    0%
        transform: scale(0)

    100%
        transform: scale(1)

@keyframes lds-ellipsis3
    0%
        transform: scale(1)

    100%
        transform: scale(0)

@keyframes lds-ellipsis2
    0%
        transform: translate(0, 0)

    100%
        transform: translate(calc(0.3 * var(--size)), 0) //24px
</style>