<template>
    <div id="itemsCollect" :class="{ show: show }">
        <form v-if="!emptyList" @submit.prevent="changePlaceForItems">
            <select name="place" ref="submitplace_place" @change="changePlaceInForm">
                <option value="add_new_place">Neuer Ort</option>
                <option v-for="(place, index) in store_allPlaces" :value="place.place_id" :selected="index == 0">
                    {{ place.place_name }}
                </option>
            </select>
            <button v-if="loadingSetItems">
                <MovingPoints size=".7rem" :box=false />
            </button>
            <button v-else>{{ placeButtonText }}</button>
        </form>
        <div v-if="emptyList" class="defaultMsg">
            <p>Es wurden noch keine Items hinzugefügt.</p>
        </div>
        <div class="items" v-if="!emptyList">
            <div class="heading">
                <p>Ausgewählte Items</p>
                <span class="my-material-icons" @click="store_clearCurrentItems">delete</span>
            </div>
            <GroupedItems :groupedItemList="store_getCurrentItemsGrouped" :lighten="false" :showMaxCount="false" />
        </div>
    </div>
</template>

<script>
import GroupedItems from "@/components/GroupedItems.vue";
import MovingPoints from "@/components/loader/MovingPoints.vue"
import { mapGetters, mapActions } from "vuex";


export default {
    name: "Cart",
    components: {
        GroupedItems,
        MovingPoints,
    },
    props: {
        show: Boolean,
    },
    data() {
        return {
            placeButtonText: "Items platzieren",
            loadingSetItems: false,
        };
    },
    methods: {
        ...mapActions({
            store_submitCurrentItems: "items/submitCurrentItems",
            store_clearCurrentItems: "items/clearCurrentItems",
        }),
        changePlaceInForm() {
            if (this.$refs.submitplace_place.value == "add_new_place")
                this.placeButtonText = "Ort erstellen";
            else this.placeButtonText = "Items platzieren";
        },
        async changePlaceForItems() {
            if (this.loadingSetItems) {
                this.$toast.error("Die Anfrage wurde bereits gesendet.");
                return;
            }
            this.loadingSetItems = true;
            let place_id = this.$refs.submitplace_place.value;
            if (place_id == "add_new_place") {
                this.$router.push({
                    name: "AddPlace",
                    query: {
                        temporary: 1,
                        quickadd: 1,
                        redirect: this.$route.fullPath,
                    },
                });
                this.loadingSetItems = false;
                return
            }
            await this.store_submitCurrentItems(place_id)
                .then((res) => {
                    this.$toast.success("Items erfolgreich platziert.");
                    this.$emit("hide");
                })
                .catch((err) => {
                    this.$toast.error(err);
                });
            this.loadingSetItems = false;
        },
    },
    computed: {
        ...mapGetters({
            store_getCurrentItemsGrouped: "items/getCurrentItemsGrouped",
            store_allPlaces: "places/getPlaces",
        }),
        emptyList() {
            return !(Object.keys(this.store_getCurrentItemsGrouped).length > 0);
        },
    },
};
</script>

<style scoped lang="sass">
#itemsCollect
    @include navigationPanel(left, 100%)
    color: #FFF
    padding-inline: 10px
    padding-bottom: 5px
    font-size: 1rem
    text-decoration: none
    text-align: right
    z-index: 2

    .defaultMsg
        text-align: center
        font-size: 1.3rem

    $setPlaceFormHeight: 100px
    .items
        display: flex
        flex-direction: column
        text-align: left
        gap: 5px
        height: calc(100% - $setPlaceFormHeight)
        overflow: auto

        > .heading
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            padding: 10px
            background-color: color_primary(90%)
            border-radius: 10px
            color: $color_primary

            p
                margin: 0
                font-size: 1.2rem
                font-weight: bold

            span
                font-size: 25px

    form
        display: grid
        grid-template-columns: 1fr 1fr
        gap: 0 15px
        box-sizing: border-box
        padding-top: 20px
        height: $setPlaceFormHeight
        justify-content: center
        align-items: center
        overflow: visible

        &:after
            grid-column: 1 / span 2
            width: 100%
            content: ""
            display: block
            height: 1px
            margin-bottom: 5px
            background-color: #FFF

        select, input, button
            width: 100%
            background-color: $color_primary
            border: 2px solid #FFF
            border-radius: 10px
            padding: 7px 3px
            font-size: 1rem
            color: #FFF
            box-shadow: 0 0 10px 2px color_primary(50)

        button
            display: table-cell
            vertical-align: center

</style>