<template>
    <div class="logList" v-if="data.length > 0">
        <div v-for="log in data" :key="log?.transport?.id ?? log.logs[0].id">
            <LogEntry v-if="log.transport == null" :data="log.logs[0]" />
            <TransportLogEntry v-else :data="log" />
        </div>
    </div>
    <p v-else>Noch keine Logfiles vorhanden.</p>
</template>

<script>
import LogEntry from "./LogEntry.vue";
import TransportLogEntry from "./TransportLogEntry.vue";
export default {
    name: "LogList",
    components: { LogEntry, TransportLogEntry },
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
};
</script>
