<template>
    <div class="item" :class="{ noPlace: !showPlace }">
        <div v-if="countColumn" class="count">
            <p v-if="countBraces">(1x)</p>
            <p v-else>1x</p>
        </div>
        <div class="name" @click="showLogsFunc(true)">
            <p>{{ item.item_name }}</p>
        </div>
        <div class="place" v-if="showPlace">
            <p v-if="item.item_place">{{ item.item_place.place_name }}</p>
            <p v-else>NO PLACE</p>
        </div>
        <div class="icons">
            <slot name="buttons" v-if="showButtons" :item="item">
                <AddRemoveFromCurrentButtons
                    :checked="item.checked"
                    @add="store_addItemToCurrentList(item)"
                    @remove="store_removeItemFromCurrentList(item)"
                />
            </slot>
        </div>
    </div>
    <LogModal
        v-if="logs"
        @cancel="showLogsFunc(false)"
        :item="item"
        type="Item"
    />
</template>

<script>
import { mapActions } from "vuex";
import AddRemoveFromCurrentButtons from "@/components/AddRemoveFromCurrentButtons.vue";
import LogModal from "@/components/logs/LogModal.vue";
export default {
    name: "Item",
    components: {
        AddRemoveFromCurrentButtons,
        LogModal,
    },
    props: {
        item: {
            type: Object,
            default: {},
        },
        showButtons: {
            type: Boolean,
            default: true,
        },
        showPlace: {
            type: Boolean,
            default: true,
        },
        countColumn: {
            type: Boolean,
            default: false,
        },
        countBraces: {
            type: Boolean,
            default: true,
        },
        showLogs: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            logs: false,
        };
    },
    methods: {
        ...mapActions({
            store_addItemToCurrentList: "items/addToCurrentItems",
            store_removeItemFromCurrentList: "items/removeFromCurrentItems",
        }),
        showLogsFunc(bool) {
            if (this.showLogs) {
                this.logs = bool;
            }
        },
    },
};
</script>

<style scoped lang="sass">
.item
    @include defaultItemRow
    &.noPlace
        @include defaultItemRow(fit-content(100%) 1fr fit-content(100%), "count name icon")

    .name
        grid-area: name
    .place
        grid-area: place

    .icons
        grid-area: icon
    .count
        grid-area: count
</style>