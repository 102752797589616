import axios from 'axios';
import { defaultErrCatch, setCheckedItems } from '../shared';

function _toDate(date) {
    return date.toISOString().substring(0, 10);
}
function _toDateObj(dateObj) {
    return {
        start: _toDate(new Date(dateObj.start)),
        end: _toDate(new Date(dateObj.end)),
    };
}

export default {
    namespaced: true,
    state: {
        watchlists: null,
        item_ids: [],
    },
    getters: {
        getItems(state, getters, rootState, rootGetters) {
            if (rootGetters['getAllItems'] == null) return [];
            return rootGetters['getAllItems'].filter((item) =>
                state.item_ids.includes(item.item_id)
            );
        },
        getItemIds(state) {
            return state.item_ids;
        },
        getWatchlists(state) {
            return state.watchlists;
        },
    },
    mutations: {
        setItemIds(state, item_ids) {
            state.item_ids = item_ids;
        },
        setWatchlists(state, watchlists) {
            state.watchlists = watchlists;
        },
        removeWatchlist(state, watchlist_id) {
            state.watchlists = state.watchlists.filter(
                (watchlist) => watchlist.watchlist_id !== watchlist_id
            );
        },
    },
    actions: {
        async fetchWatchlists({ commit }) {
            return axios
                .get(process.env.VUE_APP_BACKEND_URL + '/watchlists/')
                .then((res) => {
                    commit('setWatchlists', res.data);
                    return res.data;
                })
                .catch((err) => {
                    console.error(err);
                    throw 'Marklisten konnten nicht geladen werden.';
                });
        },
        async fetchListById({}, list_id) {
            return axios
                .get(
                    process.env.VUE_APP_BACKEND_URL +
                        '/watchlists/' +
                        list_id +
                        '/'
                )
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    defaultErrCatch(err);
                });
        },
        async fetchListItemsById({ commit }, list_id) {
            return axios
                .get(
                    process.env.VUE_APP_BACKEND_URL +
                        '/watchlists/' +
                        list_id +
                        '/items'
                )
                .then((res) => {
                    commit(
                        'setItemIds',
                        res.data.map((item) => item.item_id)
                    );
                    return res.data;
                })
                .catch((err) => defaultErrCatch(err));
        },
        async addWatchlist({ commit, dispatch }, { watchlist, items }) {
            return axios
                .post(
                    process.env.VUE_APP_BACKEND_URL + '/watchlists/',
                    watchlist
                )
                .then((res) => {
                    return axios
                        .post(
                            process.env.VUE_APP_BACKEND_URL +
                                '/watchlists/' +
                                res.data.watchlist_id +
                                '/items',
                            {
                                items: items,
                            }
                        )
                        .then((res_new) => {
                            dispatch('items/clearCurrentItems', null, {
                                root: true,
                            });
                            return {
                                msg: 'Merkliste hinzugefügt.',
                                watchlist_id: res.data.watchlist_id,
                            };
                        })
                        .catch((err) => defaultErrCatch(err));
                })
                .catch((err) => defaultErrCatch(err));
        },
        async editWatchlist({}, watchlist) {
            return axios
                .put(
                    process.env.VUE_APP_BACKEND_URL +
                        '/watchlists/' +
                        watchlist.watchlist_id +
                        '/',
                    watchlist
                )
                .then((res) => {
                    return res.data.watchlist_name + ' bearbeitet.';
                })
                .catch((err) => defaultErrCatch(err));
        },
        async deleteWatchlist({ commit }, list_id) {
            return axios
                .delete(
                    process.env.VUE_APP_BACKEND_URL +
                        '/watchlists/' +
                        list_id +
                        '/'
                )
                .then((res) => {
                    commit('removeWatchlist', list_id);
                    return 'Erfolgreich gelöscht.';
                })
                .catch((err) => defaultErrCatch(err));
        },
        async addDaterangeToWatchlist({}, { watchlist_id, daterange_obj }) {
            if (!(daterange_obj?.start && daterange_obj?.end))
                throw 'Wrong Daterange Obj';
            let dro = _toDateObj(daterange_obj);
            return axios
                .post(
                    process.env.VUE_APP_BACKEND_URL +
                        '/watchlists/' +
                        watchlist_id +
                        '/dates',
                    dro
                )
                .then((res) => {
                    return res.data;
                })
                .catch((err) => defaultErrCatch(err));
        },
        async deleteDaterangeOfWatchlist({}, { watchlist_id, daterange_id }) {
            return axios
                .delete(
                    process.env.VUE_APP_BACKEND_URL +
                        '/watchlists/' +
                        watchlist_id +
                        '/dates/' +
                        daterange_id +
                        '/'
                )
                .then((res) => {
                    return 'Erfolgreich gelöscht.';
                })
                .catch((err) => defaultErrCatch(err));
        },
    },
};
