import axios from 'axios';
import { defaultErrCatch } from '@/store/shared';

export default {
    namespaced: true,
    state: {
        places: null,
    },
    getters: {
        getPlaces(state) {
            return state.places;
        },
        getPlaceById: (state) => (place_id) => {
            if (state.places == null) return {};
            return state.places.find((place) => place.place_id == place_id);
        },
    },
    mutations: {
        setPlaces(state, places) {
            state.places = places;
        },
        removePlace(state, place_id) {
            state.places = state.places.filter(
                (place) => place.place_id !== place_id
            );
        },
        addPlace(state, place) {
            state.places.push(place);
        },
    },
    actions: {
        async fetchPlaces({ commit }) {
            return axios
                .get(process.env.VUE_APP_BACKEND_URL + '/places/')
                .then((res) => {
                    commit('setPlaces', res.data);
                    return res.data;
                })
                .catch((err) => {
                    throw 'Orte konnten nicht geladen werden.';
                });
        },
        async fetchPlace({}, place_id) {
            return axios
                .get(process.env.VUE_APP_BACKEND_URL + '/places/' + place_id)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw 'Ort konnte nicht geladen werden.';
                });
        },
        async addPlace({ commit }, place) {
            return axios
                .post(process.env.VUE_APP_BACKEND_URL + '/places/', place)
                .then((res) => {
                    commit('addPlace', res.data);
                    return {
                        place: res.data,
                        msg: res.data.place_name + ' hinzugefügt.',
                    };
                })
                .catch((err) => defaultErrCatch(err));
        },
        async editPlace({ commit }, place) {
            return axios
                .put(
                    process.env.VUE_APP_BACKEND_URL +
                        '/places/' +
                        place.place_id +
                        '/',
                    place
                )
                .then((res) => {
                    commit('removePlace', res.data.place_id);
                    commit('addPlace', res.data);
                    return res.data.place_name + ' erfolgreich geändert.';
                })
                .catch((err) => defaultErrCatch(err));
        },
        async deletePlace({ commit }, place_id) {
            return axios
                .delete(process.env.VUE_APP_BACKEND_URL + '/places/' + place_id)
                .then((res) => {
                    commit('removePlace', place_id);
                    return 'Erfolgreich gelöscht.';
                })
                .catch((err) => defaultErrCatch(err));
        },
    },
};
