<template>
    <div class="fullscreen">
        <slot>
            <MovingPoints />
        </slot>
    </div>
</template>

<script>
import MovingPoints from "@/components/loader/MovingPoints.vue";
export default {
    name: "FullPage",
    components: {
        MovingPoints,
    },
};
</script>

<style scoped lang="sass">
.fullscreen
    position: fixed
    top: 0
    left: 0
    background-color: color_primary_alpha(0.85)
    width: 100vw
    height: 100vh
    z-index: 15

    > *
        top: 50%
        transform: translateY(-50%)
</style>