<template>
    <div
        class="backdrop"
        :class="{ no_alpha: !alpha }"
        @click.self="$emit('cancel')"
    >
        <div class="modal">
            <button v-if="cancelButton" class="alt" @click="$emit('cancel')">
                <span class="material-icons">close</span>
            </button>
            <p class="heading">{{ heading }}</p>
            <p class="content">
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script>
import { globalScrollSet } from "@/shared";
export default {
    name: "Modal",
    props: {
        heading: {
            type: String,
            default: "",
        },
        cancelButton: {
            type: Boolean,
            default: true,
        },
        alpha: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        globalScrollSet(false);
    },
    unmounted() {
        globalScrollSet(true);
    },
};
</script>

<style lang="sass" scoped>
.backdrop
    position: fixed
    top: 0
    left: 0
    background: color_primary_alpha(0.9)
    width: 100%
    height: 100%
    z-index: 5
    &.no_alpha
        background: $color_primary

.modal
    position: relative
    box-sizing: border-box
    margin: 20vh auto
    width: 90%
    padding: 10px
    background: white
    border-radius: 1rem

    .heading
        font-size: 1.8rem
        font-weight: bold
        margin: 1rem 0

    .alt
        position: absolute
        top: 10px
        right: 10px
        width: 30px
        height: 30px
        padding: 0
        border: 2px solid $color_primary
        background-color: #FFF
        color: $color_primary
        border-radius: 100%

        span
            line-height: 20px
            font-size: 15px

    .content
        &:deep(.buttons)
            display: flex
            flex-direction: row
            justify-content: flex-end
            gap: 10px
            margin-top: 30px

@media screen and (min-width: $maxwidth)
    .modal
        width: calc(0.5 * $maxwidth)
</style>