<template>
    <nav :class="{ show: show }">
        <ul ref="menuList">
            <li class="user">
                <div>
                    <p v-if="store_getUserName">{{ store_getUserName }}</p>
                    <span @click="$emit('logout')" class="my-material-icons"
                        >logout</span
                    >
                </div>
            </li>
            <li>
                <router-link :to="{ name: 'Items' }">Home</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'CalendarView' }"
                    >Kalender</router-link
                >
            </li>
            <li>
                <router-link :to="{ name: 'ListView', query: { ro: true } }"
                    >Merklisten</router-link
                >
            </li>
            <SubMenu title="Bearbeiten">
                <li>
                    <router-link :to="{ name: 'ItemManagement' }"
                        >Items</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'Places' }">Orte</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'Categories' }"
                        >Kategorien</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'ListView' }"
                        >Merklisten</router-link
                    >
                </li>
                <li>
                    <router-link :to="{ name: 'Users' }">Benutzer</router-link>
                </li>
            </SubMenu>
            <li class="copyright">
                <a href="https://eja-online.de">&copy; eja Bautzen-Kamenz</a>
                <div class="img">
                    <a href="https://eja-online.de">
                        <img :src="eja_logo_white" alt="eja Logo" />
                    </a>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
import eja_logo_white from "@/assets/img/eja_white.svg";
import SubMenu from "@/components/header/SubMenu.vue";
import { mapGetters } from "vuex";

export default {
    name: "MyMenu",
    components: {
        SubMenu,
    },
    props: {
        show: Boolean,
    },
    data() {
        return {
            eja_logo_white: eja_logo_white,
        };
    },
    mounted() {
        if (this.$refs.menuList == null) return;
        const li = this.$refs.menuList.querySelectorAll("li a");
        for (let item of li) {
            item.addEventListener("click", (e) => {
                if (!e.target.classList.contains("router-link-exact-active"))
                    return;
                this.$emit("close");
            });
        }
    },
    computed: {
        ...mapGetters({
            store_getUserName: "auth/getName",
        }),
    },
};
</script>

<style scoped lang="sass">
nav
    @include navigationPanel(left, -100%)
    color: #FFF
    font-size: 2rem
    text-decoration: none
    text-transform: uppercase
    z-index: 3

    :deep(ul)
        display: flex
        flex-direction: column
        gap: 2rem
        height: 100%
        padding: 20px 0
        box-sizing: border-box
        margin: 0
        overflow: auto
        list-style: none

    .user
        > div
            display: flex
            gap: 40px
            justify-content: center

        p
            margin: 0
            font-weight: bold
            user-select: none

        &:after
            content: ""
            display: block
            width: 80%
            height: 1px
            background-color: color_primary(70)
            margin-inline: auto
            margin-top: 30px

    .copyright
        font-size: 1rem

        &:before
            content: ""
            display: block
            width: 80%
            height: 1px
            background-color: color_primary(70)
            margin-inline: auto
            margin-block: 30px

        .img
            display: block
            margin-inline: auto
            margin-top: 10px

            img
                width: 100px
</style>