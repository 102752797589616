<template>
    <Modal :heading="itemHeading" @cancel="$emit('cancel')">
        <div v-if="!loading" class="mycontent">
            <LogList :data="sortedData" />
            <p
                class="g_button loadMore"
                v-if="more.show && data.length > 0"
                @click="loadMore"
            >
                Mehr laden
            </p>
        </div>
        <div v-if="loading || more.loading" class="loader">
            <p v-if="errmsg">{{ errmsg }}</p>
            <MovingPoints v-else />
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import LogList from "@/components/logs/LogList.vue";
import MovingPoints from "@/components/loader/MovingPoints.vue";
import { mapActions } from "vuex";

export default {
    name: "LogModal",
    props: {
        item: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    components: {
        Modal,
        LogList,
        MovingPoints,
    },
    data() {
        return {
            loading: true,
            data: [],
            sortedData: [],
            errmsg: null,
            chunk: 1,
            more: {
                show: true,
                loading: false,
            },
        };
    },
    async mounted() {
        if (!(await this.loadLogs())) return;
        this.loading = false;
    },
    methods: {
        ...mapActions({
            store_getLogs: "logs/getLogs",
            store_sortLogsForTransport: "logs/sortLogsTransport",
        }),
        async loadLogs(chunk = 0) {
            let data = await this.store_getLogs({
                item: this.item,
                type: this.type,
                chunk: chunk,
            }).catch((err) => {
                this.$toast.error(err);
                this.errmsg = "Logdaten konnten nicht geladen werden.";
            });
            if (!data) return false;
            this.data.push(...data);
            this.sortedData = await this.store_sortLogsForTransport(this.data);
            return true;
        },
        async loadMore() {
            this.more.loading = true;
            this.more.show = false;
            let length = this.data.length;
            if (!(await this.loadLogs(this.chunk))) return;
            this.more.loading = false;
            if (this.data.length == length) {
                this.$toast.error("Keine weiteren Logdaten vorhanden.");
                return;
            }
            this.more.show = true;
            this.chunk++;
        },
    },
    computed: {
        itemHeading() {
            switch (this.type) {
                case "Item":
                    return `Logs: ${this.item.item_name}`;
                case "Group":
                    return `Logs: ${this.item.group_name}`;
                case "Place":
                    return `Logs: ${this.item.place_name}`;
                case "Category":
                    return `Logs: ${this.item.category_name}`;
                case "WatchList":
                    return `Logs: ${this.item.watchlist_name}`;
                case "User":
                    return `Logs: ${this.item.user_name}`;
                default:
                    return "Logs";
            }
        },
    },
};
</script>

<style scoped lang="sass">
:deep(.modal)
    display: grid
    grid-template-rows: min-content 1fr
    margin-block: 5vh
    height: 90vh

:deep(.content)
    overflow: auto

.loader
    width: fit-content
    margin-inline: auto

    :deep(.lds-ellipsis div)
        background-color: $color_primary

.mycontent .loadMore
    width: fit-content
    margin: 1rem auto
    background-color: #FFF
    color: $color_primary
    border: 2px solid $color_primary
    padding: 5px
    font-size: 1rem
</style>