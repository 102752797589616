<template>
    <Login v-if="!isAuthenticated" />
    <div v-if="isAuthenticated">
        <header>
            <div>
                <div>
                    <div @click="show.menu = !show.menu" class="menu_icon">
                        <span
                            :class="{ rotate: show.menu }"
                            class="my-material-icons"
                            >menu</span
                        >
                    </div>
                    <img
                        @click="$router.push({ name: 'Items' })"
                        class="eja_logo"
                        :src="eja_logo_white"
                        alt="eja Logo"
                    />
                </div>
                <div>
                    <div @click="$router.push({ name: 'AddList' })">
                        <span class="my-material-icons">save</span>
                    </div>
                    <div @click="$router.push({ name: 'QRScanner' })">
                        <span class="my-material-icons">qr_code_scanner</span>
                    </div>
                    <div class="cartBox" @click="show.items = !show.items">
                        <span class="my-material-icons icon">task_alt</span>
                        <span
                            class="currentAmount"
                            v-if="store_currentItemAmount > 0"
                            >{{ store_currentItemAmount }}</span
                        >
                    </div>
                </div>
            </div>
        </header>
        <div id="mainViewport">
            <MyMenu
                :show="show.menu"
                @logout="logout"
                @close="show.menu = false"
            />
            <main>
                <router-view />
            </main>
            <Cart :show="show.items" @hide="show.items = false" />
        </div>
    </div>
    <!-- <FullPage v-if="loading && isAuthenticated" /> -->
</template>

<script>
import Login from '@/components/header/Login.vue';
import FullPage from '@/components/loader/FullPage.vue';

import Cart from '@/components/header/Cart.vue';
import MyMenu from '@/components/header/MyMenu.vue';

import eja_logo_white from '@/assets/img/eja_white.svg';

import { globalScrollSet } from '@/shared';

import { mapActions, mapGetters } from 'vuex';
export default {
    name: 'App',
    components: {
        Cart,
        MyMenu,
        FullPage,
        Login,
    },
    data() {
        return {
            eja_logo_white: eja_logo_white,
            show: {
                menu: false,
                items: false,
            },
        };
    },
    watch: {
        $route(to, from) {
            this.show.menu = false;
            this.show.items = false;
            window.scrollTo({ top: 0 });
        },
        showMenu(to, from) {
            this.onSiteViewChange();
        },
        showItems(to, from) {
            this.onSiteViewChange();
        },
        isAuthenticated(to, from) {
            this.onSiteViewChange();
        },
    },
    async mounted() {
        await this.store_checkauth();
        if (this.isAuthenticated) {
            let i = await this.store_fetchAllTempData();
        }
    },
    methods: {
        ...mapActions({
            store_checkauth: 'auth/checkAuth',
            store_logout: 'auth/logout',
            store_fetchAllTempData: 'fetchAllTempData',
        }),
        logout() {
            this.store_logout();
            this.show.menu = false;
            this.show.items = false;
        },
        onSiteViewChange() {
            if (this.showMenu || this.showItems) {
                globalScrollSet(false);
            } else {
                globalScrollSet(true);
            }
        },
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            store_currentItemAmount: 'items/getCurrentItemAmount',
        }),
        showMenu() {
            return this.show.menu;
        },
        showItems() {
            return this.show.items;
        },
    },
};
</script>

<style lang="sass">
.no_scroll
    overflow: hidden

h1
    margin-bottom: 40px
    padding-inline: 20px
    text-transform: uppercase

.g_button
    background-color: $color_primary
    color: #FFF
    border: none
    padding: 10px
    border-radius: 10px
    text-transform: uppercase
    text-overflow: ellipsis
    overflow: hidden

.my-material-icons
    display: inline-block
    font-family: 'Material Icons'
    text-transform: none
    font-size: inherit
    font-weight: 400
    user-select: none

.cartBox
    display: grid
    grid-template-rows: 1fr min-content

    .currentAmount
        font-size: max(.8rem, 40%)

#app
    font-family: Century Gothic, sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    text-align: center
    color: $color_primary

html
    font-size: 16px
    // letter-spacing: 1px

body
    margin: 0

header
    position: sticky
    top: 0
    z-index: 4
    padding: 0 20px
    height: $headerheight
    background-color: $color_primary
    font-size: 2rem
    font-weight: bold
    text-transform: uppercase
    user-select: none
    letter-spacing: 2px
    color: #fff

    > div
        display: flex
        justify-content: space-between
        align-items: center
        height: $headerheight

        > div
            display: flex
            flex-direction: row
            height: 100%
            gap: 15px
            align-items: center

        .my-material-icons
            font-size: 24px

        .menu_icon
            span
                transform: rotate(0)
                transition: transform 0.3s ease-in-out

            .rotate
                transform: rotate(90deg)

        .eja_logo
            height: 70%

#mainViewport
    padding-block: 1rem
    overflow: hidden
    position: relative
    min-height: $mincontentheight
    box-sizing: border-box

    .show
        transform: translateX(0) !important

    main
        box-sizing: border-box
        width: 100%

a
    font-weight: bold
    color: inherit
    text-decoration: inherit

    &.router-link-exact-active
        color: $color_link_active

// NUMBER NO ARROWS
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

/* Firefox */
input[type=number]
    -moz-appearance: textfield
// ------------

@media screen and (min-width: $maxwidth)
    #mainViewport
        background-color: #FFF
        width: $maxwidth
        margin-inline: auto
    #itemsCollect
        > *
            width: $maxwidth
            margin-inline: auto
    header
        > div
            width: calc($maxwidth - 40px)
            margin-inline: auto
</style>
