<template>
    <div>
        <div v-if="checked" @click="$emit('remove')">
            <span class="my-material-icons itemListButton">remove_circle</span>
        </div>
        <div v-else @click="$emit('add')">
            <span class="my-material-icons itemListButton">add_circle</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Item",
    props: {
        checked: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style scoped lang="sass">
.itemListButton
    font-size: $icon_size
</style>