import '@fontsource/material-icons';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] =
    'Bearer ' + store.getters['auth/getAccessToken'];

// Toasts
import Toaster from '@meforma/vue-toaster';
import { toasterOptions } from './shared';

createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(Toaster, toasterOptions)
    .mount('#app');
