<template>
    <div class="group">
        <div class="groupElement" :class="{ noPlace: !showGroupPlaces }">
            <div v-if="countColumn && countString" class="count">
                <p>{{ countString }}</p>
            </div>
            <div class="open" @click="showItems = !showItems">
                <span
                    class="my-material-icons icon"
                    :class="{ icon_rotate: showItems }"
                    >expand_circle_down</span
                >
            </div>
            <div class="name">
                <p @click="showLogsFunc(true)">{{ group.group_name }}</p>
                <span
                    v-if="
                        (group.checked || bypassGroupChecked) &&
                        countString &&
                        !countColumn
                    "
                    >{{ countString }}</span
                >
            </div>
            <div class="place" v-if="showGroupPlaces">
                <div v-for="place in group.places">
                    <p>{{ place.name }}</p>
                </div>
                <p v-if="Object.keys(group.places).length == 0">NO PLACES</p>
            </div>
            <div class="icons">
                <slot name="buttons" v-if="showGroupButtons" :group="group">
                    <AddRemoveFromCurrentButtons
                        :checked="group.checked"
                        @click.prevent=""
                        @add="store_addListItemToCurrentList(group.items)"
                        @remove="store_removeListFromCurrentItems(group.items)"
                    />
                </slot>
            </div>
        </div>
        <div v-show="showItems" v-for="item in group.items">
            <Item
                :item="item"
                :showButtons="showItemButtons"
                :showPlace="showItemPlaces"
                :countColumn="countColumn"
                :countBraces="countBraces"
                :showLogs="showLogsItems"
            >
                <template #buttons="{ item }">
                    <slot name="itemButtons" :item="item"></slot>
                </template>
            </Item>
        </div>
    </div>
    <LogModal
        v-if="logs"
        @cancel="showLogsFunc(false)"
        :item="group"
        type="Group"
    />
</template>

<script>
import { mapActions } from "vuex";
import Item from "@/components/Item.vue";
import AddRemoveFromCurrentButtons from "@/components/AddRemoveFromCurrentButtons.vue";
import LogModal from "@/components/logs/LogModal.vue";

export default {
    name: "Group",

    props: {
        group: {
            type: Object,
            default: {},
        },
        showCheckedCount: {
            type: Boolean,
            default: true,
        },
        showMaxCount: {
            type: Boolean,
            default: true,
        },
        showGroupButtons: {
            type: Boolean,
            default: true,
        },
        showItemButtons: {
            type: Boolean,
            default: true,
        },
        showGroupPlaces: {
            type: Boolean,
            default: true,
        },
        showItemPlaces: {
            type: Boolean,
            default: true,
        },
        bypassGroupChecked: {
            type: Boolean,
            default: false,
        },
        countColumn: {
            type: Boolean,
            default: false,
        },
        countBraces: {
            type: Boolean,
            default: true,
        },
        showLogsItems: {
            type: Boolean,
            default: false,
        },
        showLogsGroup: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Item,
        AddRemoveFromCurrentButtons,
        LogModal,
    },
    data() {
        return {
            showItems: false,
            countString: "",
            logs: false,
        };
    },
    watch: {
        group(to, from) {
            this.updateCountString();
        },
    },
    mounted() {
        this.updateCountString();
    },
    methods: {
        ...mapActions({
            store_addListItemToCurrentList: "items/addListToCurrentItems",
            store_removeListFromCurrentItems:
                "items/removeListFromCurrentItems",
        }),
        showLogsFunc(bool) {
            if (this.showLogsGroup) {
                this.logs = bool;
            }
        },
        updateCountString() {
            if (this.showCheckedCount || this.showMaxCount) {
                if (this.countBraces) this.countString = "(";
                else this.countString = "";
                if (this.showCheckedCount)
                    this.countString += this.group.checked_count;
                if (this.showMaxCount && this.showCheckedCount)
                    this.countString += "/";
                if (this.showMaxCount)
                    this.countString += this.group.items.length;
                if (this.countBraces) this.countString += ")";
                if (this.countColumn) this.countString += "x";
            }
        },
    },
};
</script>

<style scoped lang="sass">
.group
    .groupElement
        @include defaultItemRow(fit-content(100%) 30px 1fr 1fr fit-content(100%), "count open name place icon")
        &.noPlace
            @include defaultItemRow(fit-content(100%) 30px 1fr fit-content(100%), "count open name icon")

        .open
            grid-area: open
        .name
            grid-area: name
            p
                display: inline-block
            span
                margin: 0 8px
                color: #999

        .place
            grid-area: place
        .icons
            grid-area: icon
        .count
            grid-area: count
    .icon
        font-size: $icon_size
        transform: rotate(0)
        transition: all 0.3s ease-in-out

    .icon_rotate
        transform: rotate(-180deg)
</style>