<template>
    <div
        class="groupedItems"
        :class="{ striped: striped, lighten: lighten, darken: !lighten }"
    >
        <div v-for="(item, item_id) in groupedItemList">
            <Group
                v-if="item_id[0] == 'g'"
                :group="item"
                :showCheckedCount="showCheckedCount"
                :showMaxCount="showMaxCount"
                :showGroupPlaces="showGroupPlaces"
                :showItemPlaces="showItemPlaces"
                :showGroupButtons="showGroupButtons"
                :showItemButtons="showItemButtons"
                :bypassGroupChecked="bypassGroupChecked"
                :countColumn="countColumn"
                :countBraces="countBraces"
                :showLogsGroup="showLogsGroups"
                :showLogsItems="showLogsItems"
            >
                <template #buttons>
                    <slot name="groupButtons" :group="item"></slot>
                </template>
                <template #itemButtons="{ item }">
                    <slot name="itemButtons" :item="item"></slot>
                </template>
            </Group>
            <Item
                v-if="item_id[0] == 'i'"
                :item="item"
                :showPlace="showItemPlaces"
                :showButtons="showItemButtons"
                :countColumn="countColumn"
                :countBraces="countBraces"
                :showLogs="showLogsItems"
            >
                <template #buttons>
                    <slot name="itemButtons" :item="item"></slot>
                </template>
            </Item>
        </div>
    </div>
</template>

<script>
import Item from "@/components/Item.vue";
import Group from "@/components/Group.vue";
export default {
    name: "GroupedItems",
    components: {
        Item,
        Group,
    },
    props: {
        groupedItemList: {
            type: Object,
            default: {},
        },
        showCheckedCount: {
            type: Boolean,
            default: true,
        },
        showMaxCount: {
            type: Boolean,
            default: true,
        },
        striped: {
            type: Boolean,
            default: true,
        },
        lighten: {
            type: Boolean,
            default: true,
        },
        showGroupPlaces: {
            type: Boolean,
            default: true,
        },
        showItemPlaces: {
            type: Boolean,
            default: true,
        },
        showGroupButtons: {
            type: Boolean,
            default: true,
        },
        showItemButtons: {
            type: Boolean,
            default: true,
        },
        bypassGroupChecked: {
            type: Boolean,
            default: false,
        },
        countColumn: {
            type: Boolean,
            default: false,
        },
        countBraces: {
            type: Boolean,
            default: true,
        },
        showLogsItems: {
            type: Boolean,
            default: false,
        },
        showLogsGroups: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="sass">
.groupedItems
    display: flex
    flex-direction: column
    gap: 3px
    text-align: left

.striped.darken
    > div:nth-child(2n+1)
        background-color: color_primary($darken)

.striped.lighten
    > div:nth-child(2n+1)
        background-color: color_primary($lighten)
</style>