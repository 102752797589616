<template>
    <p class="title">{{ data.key }}:</p>
    <div class="compare">
        <div class="old" v-if="showOld" :class="{ mult: data.multiple }">
            <ul v-if="data.multiple && data.old.length > 0">
                <li v-for="entry in data.old">
                    {{ entry }}
                </li>
            </ul>
            <p v-else-if="data.multiple && data.old.length == 0">---</p>
            <p v-else-if="data.old.length > 0">{{ data.old }}</p>
            <p v-else-if="data.old.length == 0">---</p>
            <p v-else>ERROR</p>
        </div>
        <div class="arrow" v-if="showOld && showNew">
            <p class="my-material-icons" v-if="!data.multiple">arrow_forward</p>
        </div>
        <div class="new" v-if="showNew">
            <ul v-if="data.multiple && data.new.length > 0">
                <li v-for="entry in data.new">
                    {{ entry }}
                </li>
            </ul>
            <p v-else-if="data.multiple && data.new.length == 0">---</p>
            <p v-else-if="data.new.length > 0">{{ data.new }}</p>
            <p v-else-if="data.new.length == 0">---</p>
            <p v-else>ERROR</p>
        </div>
    </div>
</template>

<script>
const showOld = [1, 2];
const showNew = [0, 1];

export default {
    name: "AttributeContainer",
    props: {
        data: {
            type: Object,
            required: true,
        },
        action_flag: {
            type: Number,
            required: true,
        },
    },
    computed: {
        showNew() {
            return showNew.includes(this.action_flag);
        },
        showOld() {
            return showOld.includes(this.action_flag);
        },
    },
};
</script>

<style scoped lang="sass">
.title
    font-weight: bold
    font-size: 1.1rem
    margin-bottom: 5px
    margin-top: 15px

p
    margin: 0

.compare
    display: grid
    grid-template-columns: 1fr min-content 1fr
    gap: 2px

    > div // new and old
        position: relative
        padding: 5px
        font-size: .9rem
        border-radius: 5px

        > span
            position: absolute
            top: 3px
            right: 3px

        > ul
            padding: 0
            padding-left: 0.8rem
            margin: 0

            li
                padding-left: 3px

                &::marker
                    display: inline-block
                    font-size: 0.6rem
                    @include Material_Icons

=boxShadow($color)
    box-shadow: 0 0 5px 0 $color inset

$red: #B00
$green: #070

.new
    @include boxShadow($green)

    li::marker
        content: 'add_circle'
        color: $green

.old
    @include boxShadow($red)

    li::marker
        content: 'remove_circle'
        color: $red
</style>