<template>
    <Modal heading="" :cancelButton="false" :alpha="false">
        <form id="loginForm" @submit.prevent="login()">
            <img :src="eja_logo_white" alt="eja Logo" class="eja_logo" />
            <input type="password" inputmode="numeric" pattern="[0-9]*" id="pin" :class="{ wrong_pin: wrong_pin }"
                v-model="pin" ref="login_pin" placeholder="Pin" required />
            <input type="submit" value="Login" />
        </form>
        <div v-if="msg">
            <p>
                {{ msg }}
            </p>
        </div>
        <FullPage v-if="loading" />
    </Modal>
</template>

<script>
import { mapActions } from "vuex";
import eja_logo_white from "@/assets/img/eja_white.svg";
import Modal from "@/components/Modal.vue";
import FullPage from "@/components/loader/FullPage.vue";
export default {
    name: "Login",
    components: {
        Modal,
        FullPage,
    },
    props: {},
    data() {
        return {
            eja_logo_white: eja_logo_white,
            wrong_pin: false,
            loading: false,
            pin: "",
            msg: "",
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.login_pin.focus();
        });
    },
    methods: {
        ...mapActions({
            store_login: "auth/login",
        }),
        async login() {
            this.wrong_pin = false;
            this.loading = true;
            await this.store_login(this.pin)
                .then((res) => {
                    console.log("Logged In");
                    this.msg = "";
                })
                .catch((err) => {
                    if (err?.response?.data && err?.response?.data?.detail) {
                        this.msg = err.response.data.detail;
                    } else if (err?.response?.status === 0 || err?.response?.status >= 500) {
                        this.msg = "Netzwerkfehler - Server nicht erreichbar.";
                    }
                    else {
                        this.msg = "Login fehlgeschlagen.";
                    }
                    this.$toast.error(this.msg);
                    this.wrong_pin = true;
                    this.$refs.login_pin.focus();
                });
            this.loading = false;
            this.pin = "";
        },
    },
};
</script>

<style scoped lang="sass">
#loginForm
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 1rem

    .eja_logo
        position: absolute
        top: -17.5vh
        max-width: 80%
        max-height: 15vh

    .wrong_pin::placeholder
        color: red

    input
        width: 60%
        box-sizing: border-box
        padding: 10px 10px
        border: none
        outline: 2px solid $color_primary
        border-radius: 20px
        background-color: transparent
        color: $color_primary
        font-size: 1.4rem
        text-align: center
        letter-spacing: 5px
        text-transform: uppercase

        &::placeholder
            color: $color_primary
            opacity: 0.5

        &:focus
            outline-width: 4px

    input[type=submit]
        width: 75%
        background-color: $color_primary
        color: #FFF
        letter-spacing: 3px
</style>