import { createRouter, createWebHistory } from 'vue-router';
import ItemView from '@/views/ItemView.vue';

import store from '@/store/index.js';

import { createToaster } from '@meforma/vue-toaster';
import { toasterOptions } from '@/shared';
const toaster = createToaster(toasterOptions);

const routes = [
    {
        path: '/',
        name: 'Items',
        params: ['sort'],
        component: ItemView,
    },
    {
        path: '/users',
        component: () => import('@/views/users/UserView.vue'),
        name: 'Users',
        meta: {
            title: 'Benutzer',
        },
    },
    {
        path: '/items',
        component: () => import('@/views/DefaultParent.vue'),
        meta: {
            title: 'Item',
        },
        children: [
            {
                path: '',
                name: 'ItemManagement',
                component: () => import('@/views/items/ItemView.vue'),
            },
            {
                path: 'add',
                name: 'AddItemManagement',
                component: () => import('@/views/items/AddItemView.vue'),
            },
            {
                path: 'edit/:item_id',
                name: 'EditItemManagement',
                props: true,
                component: () => import('@/views/items/EditItemView.vue'),
            },
            {
                path: 'groups',
                component: () => import('@/views/DefaultParent.vue'),
                meta: {
                    title: 'Itemgruppe',
                },
                children: [
                    {
                        path: 'add',
                        name: 'AddItemGroup',
                        component: () =>
                            import('@/views/items/AddItemGroupView.vue'),
                    },
                    {
                        path: 'edit/:group_id',
                        name: 'EditItemGroup',
                        props: true,
                        component: () =>
                            import('@/views/items/EditItemGroupView.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/lists',
        component: () => import('@/views/DefaultParent.vue'),
        meta: {
            title: 'Merkliste',
        },
        children: [
            {
                path: '',
                name: 'ListView',
                component: () => import('@/views/lists/WatchlistView.vue'),
            },
            {
                path: 'show/:list_id',
                name: 'ShowList',
                props: true,
                component: () => import('@/views/lists/ShowListView.vue'),
            },
            {
                path: 'add',
                name: 'AddList',
                component: () => import('@/views/lists/AddListView.vue'),
            },
            {
                path: 'edit/:list_id',
                name: 'EditList',
                props: true,
                component: () => import('@/views/lists/EditListView.vue'),
            },
        ],
    },
    {
        path: '/places',
        component: () => import('@/views/DefaultParent.vue'),
        meta: {
            title: 'Ort',
        },
        children: [
            {
                path: '',
                name: 'Places',
                component: () => import('@/views/places/PlaceView.vue'),
            },
            {
                path: 'add',
                name: 'AddPlace',
                component: () => import('@/views/places/AddPlaceView.vue'),
            },
            {
                path: 'edit/:place_id',
                name: 'EditPlace',
                props: true,
                component: () => import('@/views/places/EditPlaceView.vue'),
            },
        ],
    },
    {
        path: '/categories',
        component: () => import('@/views/DefaultParent.vue'),
        meta: {
            title: 'Kategorie',
        },
        children: [
            {
                path: '',
                name: 'Categories',
                component: () => import('@/views/categories/CategoryView.vue'),
            },
            {
                path: 'add',
                name: 'AddCategory',
                component: () =>
                    import('@/views/categories/AddCategoryView.vue'),
            },
            {
                path: 'edit/:category_id',
                name: 'EditCategory',
                props: true,
                component: () =>
                    import('@/views/categories/EditCategoryView.vue'),
            },
        ],
    },
    {
        path: '/calendar',
        component: () => import('@/views/DefaultParent.vue'),
        meta: {
            title: 'Kalender',
        },
        children: [
            {
                path: '',
                name: 'CalendarView',
                component: () => import('@/views/CalendarView.vue'),
            },
        ],
    },
    {
        path: '/qrscanner',
        name: 'QRScanner',
        meta: {
            title: 'QR-Scanner',
        },
        component: () => import('@/views/qr/QRScannerView.vue'),
    },
    {
        path: '/qr/:type/:id',
        name: 'QR',
        props: true,
        component: () => import('@/views/qr/QR.vue'),
    },
    {
        path: '/:catchAll(.*)',
        meta: {
            title: '404',
        },
        component: () => import('@/views/NotFound.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    await store.dispatch('auth/checkAuth').catch((err) => {
        toaster.error(err);
    });
    return next();
});

// ShowListView handles the Title for the Show Watchlist Pages
router.afterEach((to, from) => {
    document.title = to.meta.title
        ? to.meta.title + ' - eja Logistics'
        : 'eja Logistics';
});

export default router;
