import axios from 'axios';
import { defaultErrCatch } from '@/store/shared';

export default {
    namespaced: true,
    state: {
        categories: null,
        item_ids: [],
    },
    getters: {
        getItems(state, getters, rootState, rootGetters) {
            if (rootGetters['getAllItems'] == null) return [];
            return rootGetters['getAllItems'].filter((item) =>
                state.item_ids.includes(item.item_id)
            );
        },
        getItemIds(state) {
            return state.item_ids;
        },
        getCategories(state) {
            return state.categories;
        },
    },
    mutations: {
        setItemIds(state, item_ids) {
            state.item_ids = item_ids;
        },
        setCategories(state, categories) {
            state.categories = categories;
        },
        addCategory(state, category) {
            state.categories.push(category);
        },
        removeCategory(state, category_id) {
            state.categories = state.categories.filter(
                (cat) => cat.category_id !== category_id
            );
        },
    },
    actions: {
        async fetchCategoryById({}, category_id) {
            return axios
                .get(
                    process.env.VUE_APP_BACKEND_URL +
                        '/categories/' +
                        category_id
                )
                .then((res) => {
                    return res.data;
                })
                .catch((err) => defaultErrCatch(err));
        },
        async fetchCategoryItemsById({ commit }, category_id) {
            return axios
                .get(
                    process.env.VUE_APP_BACKEND_URL +
                        '/categories/' +
                        category_id +
                        '/items'
                )
                .then((res) => {
                    commit(
                        'setItemIds',
                        res.data.map((item) => item.item_id)
                    );
                    return res.data;
                })
                .catch((err) => defaultErrCatch(err));
        },
        async fetchCategories({ commit }) {
            return axios
                .get(process.env.VUE_APP_BACKEND_URL + '/categories/')
                .then((res) => {
                    commit('setCategories', res.data);
                    return res.data;
                })
                .catch((err) => {
                    throw 'Kategorien konnten nicht geladen werden.';
                });
        },
        async addCategory({ commit }, category) {
            return axios
                .post(
                    process.env.VUE_APP_BACKEND_URL + '/categories/',
                    category
                )
                .then((res) => {
                    commit('addCategory', res.data);
                    return res.data;
                })
                .catch((err) => {
                    throw 'Kategorie konnte nicht hinzugefügt werden.';
                });
        },
        async editCategory({}, category) {
            return axios
                .put(
                    process.env.VUE_APP_BACKEND_URL +
                        '/categories/' +
                        category.category_id +
                        '/',
                    category
                )
                .then((res) => {
                    return res.data.category_name + ' bearbeitet.';
                })
                .catch((err) => {
                    throw 'Kategorie konnte nicht bearbeitet werden.';
                });
        },
        async deleteCategory({ commit }, category_id) {
            return axios
                .delete(
                    process.env.VUE_APP_BACKEND_URL +
                        '/categories/' +
                        category_id +
                        '/'
                )
                .then((res) => {
                    commit('removeCategory', category_id);
                    return 'Erfolgreich gelöscht.';
                })
                .catch((err) => {
                    throw 'Kategorie konnte nicht gelöscht werden.';
                });
        },
    },
};
