import axios from 'axios';

export default {
    namespaced: true,
    state: {
        accessToken: null,
        refreshToken: null,
        name: null,
    },
    getters: {
        getAccessToken(state) {
            return state.accessToken;
        },
        getRefreshToken(state) {
            return state.refreshToken;
        },
        getName(state) {
            return state.name;
        },
        isAuthenticated(state) {
            return !(state.accessToken == null || state.refreshToken == null);
        },
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
        },
        setRefreshToken(state, token) {
            state.refreshToken = token;
        },
        setName(state, name) {
            state.name = name;
        },
    },
    actions: {
        async login({ commit, getters, dispatch }, pin) {
            let loginObj = {
                pin: pin,
            };
            return axios
                .post(
                    process.env.VUE_APP_BACKEND_URL + '/auth/token/',
                    loginObj
                )
                .then((res) => {
                    commit('setAccessToken', res.data.access);
                    commit('setRefreshToken', res.data.refresh);

                    if (res.data.name) commit('setName', res.data.name);

                    axios.defaults.headers.common['Authorization'] =
                        'Bearer ' + getters['getAccessToken'];

                    dispatch('fetchAllTempData', null, { root: true });

                    return true;
                })
                .catch((err) => {
                    throw err;
                });
        },
        logout({ commit }) {
            commit('setAccessToken', null);
            commit('setRefreshToken', null);
            commit('setName', null);
        },
        async checkAuth({ getters, commit, dispatch }) {
            // console.log('checkAuth');
            if (!getters.isAuthenticated) {
                return false;
            }

            // Validate Access Token
            return axios
                .post(process.env.VUE_APP_BACKEND_URL + '/auth/token/verify/', {
                    token: getters.getAccessToken,
                })
                .then((res) => {
                    return true;
                })
                .catch((err) => {
                    // If there is no response just exit (cant handle anything then)
                    if (!err.response) throw 'No response from server';

                    // Invalid Access Token - Try to get new one with refresh Token
                    return axios
                        .post(
                            process.env.VUE_APP_BACKEND_URL +
                                '/auth/token/refresh/',
                            {
                                refresh: getters.getRefreshToken,
                            }
                        )
                        .then((res) => {
                            // We got an access Token -> save it and repeat request
                            commit('setAccessToken', res.data.access);
                            if (res.data.refresh != null) {
                                commit('setRefreshToken', res.data.refresh);
                            }
                            axios.defaults.headers.common['Authorization'] =
                                'Bearer ' + res.data.access;
                            return true;
                        })
                        .catch((err) => {
                            // Cant get new access Token -> Delete local Tokens and go to Login
                            dispatch('logout');
                            console.log('Refresh outdated.');
                            throw 'Du wurdest automatisch abgemeldet.';
                        });
                });
        },
    },
};
