<template>
    <div class="logEntry">
        <LogHeading
            :action_flag="data.action_flag"
            :heading="data.heading_name"
            :informationString="[
                data.username,
                data.object_type,
                data.date,
                data.time,
            ]"
            :showAtts="showAtts"
            :transport="data.is_transport_log"
            :deleted="data.object_deleted"
            @click="toggleAtts"
        />
        <div v-if="showAtts" class="atts">
            <AttributeContainer
                v-for="attribute in data.data"
                :key="attribute.id"
                :data="attribute"
                :action_flag="data.action_flag"
            />
        </div>
    </div>
</template>

<script>
import LogHeading from './entry/LogHeading.vue';
import AttributeContainer from './entry/AttributeContainer.vue';

export default {
    name: 'LogEntry',
    components: { LogHeading, AttributeContainer },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showAtts: false,
        };
    },
    methods: {
        toggleAtts() {
            this.showAtts = !this.showAtts;
        },
    },
};
</script>

<style scoped lang="sass">
.atts
    border: 2px solid $color_primary
    border-radius: 0 0 10px 10px
    border-top: none
    padding: 5px
    padding-bottom: 15px
</style>
