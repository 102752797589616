<template>
    <SettingsRow>
        <span class="my-material-icons" @click="sort = SortEnum.place"
            >location_on</span
        >
        <span class="my-material-icons" @click="sort = SortEnum.cat"
            >category</span
        >
        <span class="my-material-icons" @click="sort = SortEnum.none"
            >list</span
        >
    </SettingsRow>
    <ContainerList v-if="sort == SortEnum.none">
        <template #heading>
            <p>Alle Items</p>
            <AddRemoveFromCurrentButtons
                :checked="checked_all"
                @add="store_addObjectToCurrentItems(defaultItems)"
                @remove="store_removeObjectFromCurrentItems(defaultItems)"
            />
        </template>
        <GroupedItems
            :groupedItemList="defaultItems"
            :countColumn="countColumn"
            :showCheckedCount="showCheckedCount"
            :showMaxCount="showMaxCount"
            :countBraces="countBraces"
            :showLogsItems="showLogsItems"
            :showLogsGroups="showLogsGroups"
        />
    </ContainerList>
    <ContainerList v-if="sort == SortEnum.place" v-for="place in placeItems">
        <template #heading>
            <p>{{ place.place_name }}</p>
            <AddRemoveFromCurrentButtons
                v-if="Object.keys(place.items).length > 0"
                :checked="place.checked"
                @add="store_addObjectToCurrentItems(place.items)"
                @remove="store_removeObjectFromCurrentItems(place.items)"
            />
        </template>
        <GroupedItems
            :groupedItemList="place.items"
            :countColumn="countColumn"
            :showCheckedCount="showCheckedCount"
            :showMaxCount="showMaxCount"
            :countBraces="countBraces"
            :showLogsItems="showLogsItems"
            :showLogsGroups="showLogsGroups"
        />
    </ContainerList>
    <ContainerList
        v-if="sort == SortEnum.cat"
        v-for="category in categoryItems"
    >
        <template #heading>
            <p>{{ category.category_name }}</p>
            <AddRemoveFromCurrentButtons
                v-if="Object.keys(category.items).length > 0"
                :checked="category.checked"
                @add="store_addObjectToCurrentItems(category.items)"
                @remove="store_removeObjectFromCurrentItems(category.items)"
            />
        </template>
        <GroupedItems
            :groupedItemList="category.items"
            :countColumn="countColumn"
            :showCheckedCount="showCheckedCount"
            :showMaxCount="showMaxCount"
            :countBraces="countBraces"
            :showLogsItems="showLogsItems"
            :showLogsGroups="showLogsGroups"
        />
    </ContainerList>
</template>

<script>
import { mapActions } from "vuex";
import GroupedItems from "@/components/GroupedItems.vue";
import AddRemoveFromCurrentButtons from "@/components/AddRemoveFromCurrentButtons.vue";
import SettingsRow from "@/components/SettingsRow.vue";
import ContainerList from "@/components/ContainerList.vue";
export default {
    name: "ViewItemsWithSort",
    components: {
        GroupedItems,
        SettingsRow,
        ContainerList,
        AddRemoveFromCurrentButtons,
    },
    props: {
        defaultSort: {
            type: String,
            default: "",
        },
        defaultItems: {
            type: Object,
            required: true,
        },
        placeItems: {
            type: Object,
            required: true,
        },
        categoryItems: {
            type: Object,
            required: true,
        },

        countColumn: {
            type: Boolean,
            default: false,
        },
        showCheckedCount: {
            type: Boolean,
            default: true,
        },
        showMaxCount: {
            type: Boolean,
            default: true,
        },
        countBraces: {
            type: Boolean,
            default: true,
        },

        showLogsItems: {
            type: Boolean,
            default: false,
        },
        showLogsGroups: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            SortEnum: {
                none: "none",
                cat: "cat",
                place: "place",
            },
            sort: "",
        };
    },
    mounted() {
        this.sort = this.defaultSort;
    },
    methods: {
        ...mapActions({
            store_addObjectToCurrentItems: "items/addObjectToCurrentItems",
            store_removeObjectFromCurrentItems:
                "items/removeObjectFromCurrentItems",
        }),
    },
    computed: {
        checked_all() {
            return Object.values(this.defaultItems).some((item) => {
                return item.checked;
            });
        },
    },
};
</script>