import axios from 'axios';
import { defaultErrCatch, getItemToPostItem } from '@/store/shared';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async fetchItem({}, item_id) {
            return axios
                .get(process.env.VUE_APP_BACKEND_URL + '/items/' + item_id)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => defaultErrCatch(err));
        },
        async addItem({ commit }, item) {
            return axios
                .post(process.env.VUE_APP_BACKEND_URL + '/items/', item)
                .then((res) => {
                    commit('addToAllItems', res.data, { root: true });
                    return res.data.item_name + ' hinzugefügt.';
                })
                .catch((err) => defaultErrCatch(err));
        },
        async addMultipleItems({ dispatch }, items) {
            let name = items.item_name;
            if (items.item_group == null) {
                let group = await dispatch('addItemGroup', {
                    group_name: items.item_name,
                });
                items.item_group = group.group_id;
            }
            let promises = [];
            for (let i = 1; i <= items.item_count; i++) {
                items.item_name = name + ' ' + i;
                promises.push(dispatch('addItem', items));
            }
            return Promise.all(promises);
        },
        async editItem({ dispatch }, item) {
            return axios
                .put(
                    process.env.VUE_APP_BACKEND_URL +
                        '/items/' +
                        item.item_id +
                        '/',
                    item
                )
                .then((res) => {
                    dispatch('fetchAllItems', null, {
                        root: true,
                    });
                    return res.data.item_name + ' bearbeitet.';
                })
                .catch((err) => defaultErrCatch(err));
        },
        async deleteItem({ commit }, item_id) {
            return axios
                .delete(process.env.VUE_APP_BACKEND_URL + '/items/' + item_id)
                .then((res) => {
                    commit('removeFromAllItems', item_id, { root: true });
                    return 'Erfolgreich gelöscht.';
                })
                .catch((err) => defaultErrCatch(err));
        },
        async editItemManyToMany(
            { dispatch },
            { item_id, field, extra_id, type }
        ) {
            if (![0, 1].includes(type)) throw 'Invalid Type';
            let item = await dispatch('fetchItem', item_id);
            item = getItemToPostItem(item);
            if (type == 0) item[field].push(extra_id);
            if (type == 1)
                item[field] = item[field].filter((id) => id != extra_id);
            return dispatch('editItem', item);
        },

        // ITEMGROUPS
        addItemGroup({}, item_group) {
            return axios
                .post(
                    process.env.VUE_APP_BACKEND_URL + '/itemgroups/',
                    item_group
                )
                .then((res) => {
                    return res.data;
                })
                .catch((err) => defaultErrCatch(err));
        },
        editItemGroup({}, item_group) {
            return axios
                .put(
                    process.env.VUE_APP_BACKEND_URL +
                        '/itemgroups/' +
                        item_group.group_id +
                        '/',
                    item_group
                )
                .then((res) => {
                    return res.data.group_name + ' bearbeitet.';
                })
                .catch((err) => defaultErrCatch(err));
        },
        deleteItemGroup({ dispatch }, group_id) {
            return axios
                .delete(
                    process.env.VUE_APP_BACKEND_URL +
                        '/itemgroups/' +
                        group_id +
                        '/'
                )
                .then((res) => {
                    dispatch('fetchAllItems', null, { root: true });
                    return 'Erfolgreich gelöscht.';
                })
                .catch((err) => defaultErrCatch(err));
        },
        addItemsToGroup({}, item_group) {
            return axios
                .post(
                    process.env.VUE_APP_BACKEND_URL +
                        '/itemgroups/' +
                        item_group.group_id +
                        '/items',
                    item_group
                )
                .then((res) => {
                    return 'Items zur Gruppe hinzugefügt.';
                })
                .catch((err) => defaultErrCatch(err));
        },
        fetchItemGroup({}, group_id) {
            return axios
                .get(
                    process.env.VUE_APP_BACKEND_URL + '/itemgroups/' + group_id
                )
                .then((res) => {
                    return res.data;
                })
                .catch((err) => defaultErrCatch(err));
        },
        fetchItemGroups() {
            return axios
                .get(process.env.VUE_APP_BACKEND_URL + '/itemgroups/')
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw 'Gruppen konnten nicht geladen werden';
                });
        },
    },
};
