import axios from 'axios';
import { defaultErrCatch } from '../shared';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getGoogleCalendarDates({}, dateISOString) {
            return axios
                .post(process.env.VUE_APP_BACKEND_URL + '/googlecalendar/', {
                    date: dateISOString,
                })
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    throw defaultErrCatch(err);
                });
        },
    },
};
