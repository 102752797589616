import axios from 'axios';
import { defaultErrCatch, sortArrayWithObj } from '@/store/shared';
import { defaultDateOptions } from '@/shared';

const OBJECT_TYPES = {
    ITEM: 'Item',
    GROUP: 'Group',
    PLACE: 'Place',
    CATEGORY: 'Category',
    WATCHLIST: 'WatchList',
    USER: 'User',
};

function getKeyName(att) {
    if (/^.*_name/.test(att)) return 'Name';
    if (/^.*_place/.test(att)) return 'Ort';
    if (/^.*_group/.test(att)) return 'Gruppe';
    if (/^.*_categories/.test(att)) return 'Kategorien';
    if (/^.*_watchlists/.test(att)) return 'Merklisten';
    if (/^.*_description/.test(att)) return 'Beschreibung';
    if (/^.*_temporary/.test(att)) return 'Temporär';
    if (/^.*_favorite/.test(att)) return 'Favorit';
    if (/^dates/.test(att)) return 'Datum';
    return 'Error';
}

function convertBool(bool) {
    if (typeof bool === 'boolean') {
        return bool ? 'Ja' : 'Nein';
    } else if (typeof bool === 'undefined') {
        return '---';
    }
    return 'ERROR';
}

const mult_atts = {
    item_categories: 'category_name',
    item_watchlists: 'watchlist_name',
};

const fk_atts = {
    item_place: 'place_name',
    item_group: 'group_name',
};

const date_ranges = ['dates'];

const bool_atts = ['place_favorite', 'place_temporary'];

function toDateStr(str) {
    return new Date(str).toLocaleDateString(undefined, defaultDateOptions);
}

const _mapFunctionDates = (item) => {
    if (item.start == item.end) return `${toDateStr(item.start)}`;
    return `${toDateStr(item.start)} - ${toDateStr(item.end)}`;
};

function generateData(data) {
    const atts = new Set();
    for (let key in data.new_data) {
        atts.add(key);
    }
    for (let key in data.old_data) {
        atts.add(key);
    }

    const resData = [];

    for (let att of atts) {
        if (!/^.*_id$/.test(att)) {
            let item = {};
            item['key'] = getKeyName(att);

            if (date_ranges.includes(att)) {
                item['multiple'] = 1;
                item['old'] =
                    data.old_data[att] && data.old_data[att].length > 0
                        ? data.old_data[att].map(_mapFunctionDates)
                        : [];
                item['new'] =
                    data.new_data[att] && data.new_data[att].length > 0
                        ? data.new_data[att].map(_mapFunctionDates)
                        : [];
            } else if (mult_atts[att]) {
                item['multiple'] = 1;
                item['old'] =
                    data.old_data[att] && data.old_data[att].length > 0
                        ? data.old_data[att].map((item) => item[mult_atts[att]])
                        : [];
                item['new'] =
                    data.new_data[att] && data.new_data[att].length > 0
                        ? data.new_data[att].map((item) => item[mult_atts[att]])
                        : [];
            } else if (fk_atts[att]) {
                item['old'] = data.old_data[att]
                    ? data.old_data[att][fk_atts[att]]
                    : '';
                item['new'] = data.new_data[att]
                    ? data.new_data[att][fk_atts[att]]
                    : '';
            } else if (bool_atts.includes(att)) {
                item['old'] = convertBool(data.old_data[att]);
                item['new'] = convertBool(data.new_data[att]);
            } else {
                item['old'] = data.old_data[att] || '';
                item['new'] = data.new_data[att] || '';
            }
            resData.push(item);
        }
    }
    delete data['new_data'];
    delete data['old_data'];
    data['data'] = resData;
    return;
}

function getIdAndType(item, type) {
    let id = null;
    let t = null;
    switch (type) {
        case OBJECT_TYPES.ITEM:
            id = item.item_id;
            t = 'items';
            break;
        case OBJECT_TYPES.GROUP:
            id = item.group_id;
            t = 'itemgroups';
            break;
        case OBJECT_TYPES.PLACE:
            id = item.place_id;
            t = 'places';
            break;
        case OBJECT_TYPES.CATEGORY:
            id = item.category_id;
            t = 'categories';
            break;
        case OBJECT_TYPES.WATCHLIST:
            id = item.watchlist_id;
            t = 'watchlists';
            break;
        case OBJECT_TYPES.USER:
            id = item.user_id;
            t = 'users';
            break;
    }
    return { id, t };
}

// GENERATE TRANSPORT SORTED LOGS
/*

{
    transport: null | {
                    ...transportObj
                },
    logs: [
        onlyThatOneLog | allLogsForTransport
    ]
}

*/
function generateTransportSortedLogs(logArray, deleteOldData = true) {
    let newLogArray = [];
    let mapTransportIDToArrayIndex = {};
    for (let log of logArray) {
        // Non Transport Logs && Logs with Transport not yet created
        if (
            log.transport == null ||
            mapTransportIDToArrayIndex[log.transport?.id] == null
        ) {
            let logObj = {
                transport: log.transport,
                logs: [],
            };

            // If transport log map TransportID to Index of Array, to add more logs later on
            if (log.transport?.id)
                mapTransportIDToArrayIndex[log.transport.id] =
                    newLogArray.length;

            // delete unnecessary information
            if (deleteOldData) delete log.transport;

            logObj.logs.push(log);
            newLogArray.push(logObj);
        } else {
            // Transport Logs with their Transport already created
            let transportId = mapTransportIDToArrayIndex[log.transport.id];
            if (deleteOldData) delete log.transport;
            newLogArray[transportId].logs.push(log);
        }
    }
    for (let obj of newLogArray) {
        sortArrayWithObj(obj.logs, 'heading_name');
    }
    return newLogArray;
}

function generateTransportedLogs(logs) {
    for (let log of logs) {
        generateData(log);
    }
    return generateTransportSortedLogs(logs);
}

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        sortLogsTransport({}, logArray) {
            return generateTransportSortedLogs(logArray, false);
        },
        async getLogs({}, { item, type, chunk }) {
            chunk = chunk ? chunk : 0;

            let { id, t } = getIdAndType(item, type);
            return axios
                .get(
                    process.env.VUE_APP_BACKEND_URL +
                        '/' +
                        t +
                        '/' +
                        id +
                        '/logs?chunk=' +
                        chunk
                )
                .then((res) => {
                    for (let log of res.data) {
                        generateData(log);
                    }
                    return res.data;
                })
                .catch((err) => {
                    throw defaultErrCatch(err);
                });
        },

        async getFirstDateOfLogs({}) {
            return axios
                .get(
                    process.env.VUE_APP_BACKEND_URL +
                        '/logs/prefetchDatesOfMonth/'
                )
                .then((res) => {
                    if (!res.data?.date)
                        throw { local_message: 'Ungültiges Datum von Server.' };
                    return new Date(res.data.date);
                })
                .catch((err) => {
                    throw defaultErrCatch(err);
                });
        },

        async getLogsForMonth({}, dateISOString) {
            return axios
                .post(
                    process.env.VUE_APP_BACKEND_URL +
                        '/logs/prefetchDatesOfMonth/',
                    { date: dateISOString }
                )
                .then((res) => {
                    let arrLogs = res.data.logs.map((date) => {
                        return new Date(date.date);
                    });
                    return [arrLogs, res.data.lists];
                })
                .catch((err) => {
                    throw defaultErrCatch(err);
                });
        },

        async getLogsForDay({}, dateISOString) {
            return axios
                .post(process.env.VUE_APP_BACKEND_URL + '/logs/getLogsOfDay/', {
                    date: dateISOString,
                })
                .then((res) => {
                    return [
                        generateTransportedLogs(res.data.logs),
                        res.data.lists,
                    ];
                })
                .catch((err) => {
                    throw defaultErrCatch(err);
                });
        },
    },
};
