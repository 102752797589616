<template>
    <div class="subMenu">
        <div class="heading" @click="show = !show">
            <span class="my-material-icons" :class="{ rotate: show }"
                >expand_circle_down</span
            >
            <p>{{ title }}</p>
        </div>
        <ul :class="{ show: show }">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
export default {
    name: "SubMenu",
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            show: false,
        };
    },
};
</script>

<style scoped lang="sass">
.subMenu
    .heading
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        gap: 20px
        font-weight: bold
        user-select: none

        span
            font-size: 80%
            transition: transform 0.3s ease-in-out

            &.rotate
                transform: rotate(-180deg)
        p
            margin: 0

    > ul
        display: none
        flex-direction: column
        gap: 1rem
        font-size: 70%

        &.show
            display: flex
</style>