<template>
    <div class="settings">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "SettingsRow",
};
</script>

<style scoped lang="sass">
.settings
    display: flex
    gap: 15px
    justify-content: flex-end
    font-size: 2rem
    padding-inline: 10px
</style>