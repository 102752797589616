import axios from 'axios';
import { groupItems, sortObj, defaultErrCatch } from '../shared';

export default {
    namespaced: true,
    state: {
        current_items: {},
    },
    getters: {
        getCurrentItems(state) {
            return state.current_items;
        },
        getCurrentItemIds(state, getters) {
            return Object.keys(getters.getCurrentItems);
        },
        getCurrentItemAmount(state, getters) {
            return Object.keys(getters.getCurrentItems).length;
        },
        getCurrentItemsGrouped(state, getters) {
            return groupItems(Object.values(getters.getCurrentItems));
        },
    },
    mutations: {
        addToCurrentItems(state, item) {
            item.checked = true;
            state.current_items[item.item_id] = item;
            state.current_items = sortObj(state.current_items, 'item_name');
        },
        removeFromCurrentItems(state, item) {
            item.checked = false;
            delete state.current_items[item.item_id];
        },
        clearCurrentItems(state) {
            state.current_items = {};
        },
    },
    actions: {
        addFromCode({ commit, dispatch, rootGetters }, { obj_type, item_id }) {
            return new Promise(async function (resolve, reject) {
                obj_type = obj_type.toLowerCase();
                switch (obj_type) {
                    case 'i':
                        let item = rootGetters['getItemById'](item_id);
                        if (item == null) reject('Item wurde nicht gefunden.');
                        if (item.checked) {
                            reject(
                                `${item.item_name} wurde bereits hinzugefügt.`
                            );
                        }
                        commit('addToCurrentItems', item);
                        resolve(item.item_name + ' hinzugefügt.');
                        break;
                    case 'g':
                        let grouped_items = rootGetters['getAllItems_grouped'];
                        let group = grouped_items[`g:${item_id}`];
                        if (group == null)
                            reject(
                                'Gruppe wurde nicht gefunden oder enthält keine Items.'
                            );
                        dispatch('addListToCurrentItems', group.items);
                        resolve(
                            group.items.length +
                                ' Items aus ' +
                                group.group_name +
                                ' hinzugefügt.'
                        );
                        break;
                    default:
                        reject('Unbekannter Typ.');
                }
            });
        },
        // SINGLE ITEMS
        addToCurrentItems({ commit }, item) {
            commit('addToCurrentItems', item);
        },
        removeFromCurrentItems({ commit }, item) {
            commit('removeFromCurrentItems', item);
        },
        // LIST OF ITEMS (like from grouped items)
        addListToCurrentItems({ dispatch }, items) {
            for (let item of items) {
                dispatch('addToCurrentItems', item);
            }
        },
        removeListFromCurrentItems({ dispatch }, items) {
            for (let item of items) {
                dispatch('removeFromCurrentItems', item);
            }
        },
        // OBJECT WITH LIST OF ITEMS OR ITEMS
        addObjectToCurrentItems({ dispatch }, items_object) {
            Object.entries(items_object).forEach(([key, value]) => {
                if (key[0] == 'i') {
                    dispatch('addToCurrentItems', value);
                } else if (key[0] == 'g') {
                    dispatch('addListToCurrentItems', value.items);
                }
            });
        },
        removeObjectFromCurrentItems({ dispatch }, items_object) {
            Object.entries(items_object).forEach(([key, value]) => {
                if (key[0] == 'i') {
                    dispatch('removeFromCurrentItems', value);
                }
                if (key[0] == 'g') {
                    dispatch('removeListFromCurrentItems', value.items);
                }
            });
        },
        // Change Place for Items
        submitCurrentItems({ commit, dispatch, getters }, place_id) {
            return axios
                .post(
                    process.env.VUE_APP_BACKEND_URL +
                        '/places/' +
                        place_id +
                        '/items',
                    {
                        items: getters.getCurrentItemIds,
                    }
                )
                .then((res) => {
                    dispatch('clearCurrentItems');
                    dispatch('fetchAllItems', null, { root: true });
                    return res.data;
                })
                .catch((err) => {
                    throw defaultErrCatch(err);
                });
        },
        // Clear Selected Items
        clearCurrentItems({ commit }) {
            commit('clearCurrentItems');
            commit('uncheckAllItems', null, { root: true });
        },
    },
};
