<template>
    <div class="cat_container">
        <div class="cat_heading">
            <slot name="heading"></slot>
        </div>
        <div class="cat_content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContainerList",
};
</script>

<style scoped lang="sass">
.cat_container
    margin-block: 30px

    .cat_heading
        display: grid
        grid-template-columns: 1fr $icon_space
        background-color: $color_primary
        color: #FFF
        align-items: center
        padding: 10px 5px
        text-align: left

        &:deep(p)
            font-size: 1.1rem
            margin: 0 0 2px

        &:deep(span)
            font-size: $icon_size

    .cat_content
        &:deep(p)
            text-align: left
</style>