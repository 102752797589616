import axios from 'axios';
import { defaultErrCatch } from '../shared';

export default {
    namespaced: true,
    state: {
        users: null,
    },
    getters: {
        getUsers(state) {
            return state.users;
        },
    },
    mutations: {
        setUsers(state, users) {
            state.users = users;
        },
    },
    actions: {
        async fetchUsers({ commit }) {
            return axios
                .get(process.env.VUE_APP_BACKEND_URL + '/users/')
                .then((res) => {
                    commit('setUsers', res.data);
                    return true;
                })
                .catch((err) => {
                    throw defaultErrCatch(err);
                });
        },
    },
};
