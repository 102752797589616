// Just for checkAuth on errCatch 401
import store from '@/store/index.js';

function sortArrayWithObj(arr, sortByKey) {
    arr.sort((a, b) => {
        return a[sortByKey].toLowerCase() < b[sortByKey].toLowerCase() ? -1 : 1;
    });
}

function sortObj(itemObj, sortByKey) {
    let sorted = Object.entries(itemObj).sort((a, b) => {
        return a[1][sortByKey].toLowerCase() < b[1][sortByKey].toLowerCase()
            ? -1
            : 1;
    });
    let obj = {};
    for (let [key, item] of sorted) {
        obj[key] = item;
    }
    return obj;
}

function sortForObject(
    items,
    extra_list,
    sub_field,
    id_field,
    name_field,
    no_id,
    no_name
) {
    if (extra_list == null || items == null) {
        return {};
    }
    let create_list = {};
    extra_list.forEach((obj) => {
        let e = Object.assign({}, obj); // Copy, not ref
        e.checked = false;
        e.items = [];
        create_list[e[id_field]] = e;
    });
    items.forEach((item) => {
        // ITEM WITHOUT SUBFIELD
        if (
            item[sub_field] == null ||
            (item[sub_field] instanceof Array && item[sub_field].length == 0)
        ) {
            // CREATE "NO SUBFIELD" FIELD
            if (create_list[no_id] == null) {
                create_list[no_id] = {
                    items: [],
                    checked: false,
                };
                create_list[no_id][id_field] = no_id;
                create_list[no_id][name_field] = no_name;
            }
            if (item.checked) create_list[no_id].checked = true;
            create_list[no_id].items.push(item);
            return;
        }
        // ITEM WITH SUBFIELD AS ARRAY
        if (item[sub_field] instanceof Array) {
            item[sub_field].forEach((sub_item) => {
                let current_obj = create_list[sub_item[id_field]];
                if (item.checked) current_obj.checked = true;
                current_obj.items.push(item);
            });
            return;
        }
        // ITEM WITH SUBFIELD AS STRING
        if (!(item[sub_field] instanceof Array)) {
            let extra_list_id = item[sub_field][id_field];
            if (item.checked) create_list[extra_list_id].checked = true;
            create_list[extra_list_id].items.push(item);
            return;
        }
    });
    Object.entries(create_list).forEach(([key, value]) => {
        if (value.items.length > 0) {
            create_list[key].items = groupItems(value.items);
        } else {
            delete create_list[key];
        }
    });
    return create_list;
}

function sortForPlace(item_array, places_array) {
    return sortForObject(
        item_array,
        places_array,
        'item_place',
        'place_id',
        'place_name',
        'no_place',
        'No Place'
    );
}

function sortForCategory(item_array, category_array) {
    return sortForObject(
        item_array,
        category_array,
        'item_categories',
        'category_id',
        'category_name',
        'no_category',
        'No Category'
    );
}

function groupItems(items) {
    if (items == null) return {};
    let items_grouped = {};
    items.forEach((item) => {
        // item got no group
        if (item.item_group == null) {
            items_grouped['i:' + item.item_id] = item;
            return;
        }
        // get group if available
        let groupObj = items_grouped['g:' + item.item_group.group_id];
        if (groupObj == null) {
            // Add group if not available yet
            let newGroupObj = {
                group_id: item.item_group.group_id,
                group_name: item.item_group.group_name,
                places: {},
                categories: {},
                items: [],
                checked: false,
                checked_count: 0,
            };
            items_grouped['g:' + item.item_group.group_id] = newGroupObj;
            groupObj = newGroupObj;
        }
        // add item to group
        groupObj.items.push(item);
        // add place to group
        if (
            item.item_place != null &&
            groupObj.places[item.item_place.place_id] == null
        ) {
            groupObj.places[item.item_place.place_id] = {
                id: item.item_place.place_id,
                name: item.item_place.place_name,
            };
        }
        // add categories to group
        if (
            item.item_category != null &&
            groupObj.categories[item.item_category.category_id] == null
        ) {
            groupObj.categories[item.item_category.category_id] = {
                id: item.item_category.category_id,
                name: item.item_category.category_name,
            };
        }
        // group checked if at least 1 item checked
        if (item.checked) {
            groupObj.checked = true;
            groupObj.checked_count++;
        }
    });

    // var items_grouped_removed_SoloGroups = new Map();
    // Object.entries(items_grouped).forEach((item) => {
    //     if (item[0][0] === 'g' && item[1].items.length == 1) {
    //         items_grouped_removed_SoloGroups.set(
    //             'i:' + item[1].items[0].item_id,
    //             item[1].items[0]
    //         );
    //     } else {
    //         items_grouped_removed_SoloGroups.set(item[0], item[1]);
    //     }
    // });
    // return Object.fromEntries(items_grouped_removed_SoloGroups);
    return items_grouped;
}

function defaultErrCatch(err) {
    if (!err?.response) {
        if (err?.local_message) throw err.local_message;
        else throw 'Serverantwort fehlerhaft - check console.';
    }
    if (err.response.status == 0)
        throw 'Netzwerkfehler - Server nicht erreichbar.';
    if (err.response.status == 403) throw 'Keine Berechtigung.';
    if (err.response.status == 401) {
        store.dispatch('auth/checkAuth');
        throw 'Token wird überprüft. Bitte erneut versuchen oder anmelden.';
    }
    if (err.response.data.detail) throw err.response.data.detail;
    if (err.response.status == 400)
        throw err.response.data[Object.keys(err.response.data)[0]];

    console.log(err);
    throw 'Unknown error - check console.';
}

function setCheckedItems(item_array, id_array) {
    let items = item_array.map((item) => {
        if (id_array.includes(item.item_id)) {
            item.checked = true;
        } else {
            item.checked = false;
        }
        return item;
    });
    return items;
}

function getItemToPostItem(item) {
    item.item_watchlists = item.item_watchlists.map((wl) => wl.watchlist_id);
    item.item_categories = item.item_categories.map((c) => c.category_id);
    if (item.item_place != null) item.item_place = item.item_place.place_id;
    if (item.item_group != null) item.item_group = item.item_group.group_id;
    return item;
}

export {
    sortArrayWithObj,
    sortObj,
    sortForPlace,
    sortForCategory,
    groupItems,
    defaultErrCatch,
    setCheckedItems,
    getItemToPostItem,
};
