<template>
    <ViewItemsWithSort
        v-if="!loading"
        :defaultSort="sort"
        :defaultItems="store_getItems_grouped"
        :placeItems="store_getItems_sortPlaces"
        :categoryItems="store_getItems_sortCategories"
        :showLogsItems="true"
    />
    <FullPage v-if="loading" />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ViewItemsWithSort from "@/components/ItemView/ViewItemsWithSort.vue";
import FullPage from "@/components/loader/FullPage.vue";
export default {
    name: "Items",
    components: {
        ViewItemsWithSort,
        FullPage,
    },
    data() {
        return {
            sort: "cat",
            loading: true,
        };
    },
    async mounted() {
        await this.store_fetchItems();
        this.sort = this.$route.query.sort || "cat";
        this.loading = false;
    },
    methods: {
        ...mapActions({
            store_fetchItems: "fetchAllItems",
        }),
    },
    computed: {
        ...mapGetters({
            store_getItems_grouped: "getAllItems_grouped",
            store_getItems_sortPlaces: "getAllItems_sortPlaces",
            store_getItems_sortCategories: "getAllItems_sortCategories",
        }),
    },
};
</script>