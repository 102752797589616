function globalScrollSet(bool) {
    if (bool) {
        document.getElementById('main_body').classList.remove('no_scroll');
    } else {
        document.getElementById('main_body').classList.add('no_scroll');
    }
}

const toasterOptions = { position: 'bottom', duration: 3000 };

const defaultDateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};

export { toasterOptions, defaultDateOptions, globalScrollSet };
