import { createStore } from 'vuex';
import createPersistentStore from 'vuex-persistedstate';
import axios from 'axios';
import auth from './modules/auth';
import items from './modules/items';
import places from './modules/places';
import category from './modules/category';
import itemmanagement from './modules/itemmanagement';
import watchlists from './modules/watchlists';
import logs from './modules/logs';
import users from './modules/users';
import googlecalendar from './modules/googlecalendar';
import {
    sortForPlace,
    sortForCategory,
    groupItems,
    setCheckedItems,
} from './shared';

export default createStore({
    state: {
        all_items: null,
    },
    getters: {
        getItemById: (state) => (item_id) => {
            return state.all_items.find((item) => item.item_id === item_id);
        },
        getAllItems(state, getters) {
            if (state.all_items == null) {
                return null;
            }
            return setCheckedItems(
                state.all_items,
                getters['items/getCurrentItemIds']
            );
        },
        getAllItems_grouped(state, getters) {
            return groupItems(getters.getAllItems);
        },
        getAllItems_sortPlaces(state, getters) {
            return sortForPlace(
                getters.getAllItems,
                getters['places/getPlaces']
            );
        },
        getAllItems_sortCategories(state, getters) {
            return sortForCategory(
                getters.getAllItems,
                getters['category/getCategories']
            );
        },
    },
    mutations: {
        setAllItems(state, items) {
            state.all_items = items;
        },
        uncheckAllItems(state) {
            state.all_items.forEach((item) => (item.checked = false));
        },
        addToAllItems(state, item) {
            state.all_items.push(item);
        },
        removeFromAllItems(state, item_id) {
            state.all_items = state.all_items.filter(
                (i) => i.item_id !== item_id
            );
        },
    },
    actions: {
        async fetchAllItems({ commit }) {
            return axios
                .get(process.env.VUE_APP_BACKEND_URL + '/items/')
                .then((res) => {
                    commit('setAllItems', res.data);
                    return res.data;
                })
                .catch((err) => {
                    throw 'Items konnten nicht geladen werden.';
                });
        },
        fetchAllTempData({ dispatch }) {
            return Promise.all([
                dispatch('fetchAllItems'),
                dispatch('places/fetchPlaces'),
                dispatch('category/fetchCategories'),
            ]);
        },
    },
    modules: {
        auth,
        items,
        places,
        category,
        watchlists,
        itemmanagement,
        logs,
        users,
        googlecalendar,
    },
    plugins: [createPersistentStore({ paths: ['auth', 'items'] })],
});
